<template>
    <v-dialog persistent :value="isFormActive" max-width="850">
        <v-card :loading="isLoading" :disabled="isLoading">
            <v-card-title>
                <span class="text-h5">{{ getTrad(`Form.${action}.user`) }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <ValidationObserver ref="observerFormUser" v-slot="{ handleSubmit, reset }">
                        <v-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-avatar :color="hasPicture ? '' : 'primary'" class="v-avatar mb-4" tile :class="hasPicture ? '' : 'v-avatar-light-bg primary--text'" size="120px">
                                        <v-img v-if="hasPicture" :src="resolveImg(form.picture, 'thumbnail')"></v-img>
                                        <span v-else class="font-weight-medium display-2">{{ avatarText(form.name) }}</span>
                                        <v-file-input v-show="!isDisabled" @change="onUpload" v-model="file" class="file" hide-input></v-file-input>
                                    </v-avatar>

                                    <ValidationProvider v-show="false" vid="userId">
                                        <v-text-field v-model="form.id"></v-text-field>
                                    </ValidationProvider>

                                    <ValidationProvider :name="getTrad('Form.name')" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                            class="mb-4"
                                            :error-messages="errors"
                                            v-model.trim="form.name"
                                            :prepend-inner-icon="mdiAccount"
                                            :label="getTrad('Form.name') + '*'"
                                            placeholder="John Smith"
                                            @change="setUsername"
                                            hide-details="auto"
                                            outlined
                                            dense></v-text-field>
                                    </ValidationProvider>

                                    <ValidationProvider mode="lazy" ref="providerUsername" :name="getTrad('Form.username')" rules="required|uniqueUsername:@userId" v-slot="{ errors }">
                                        <v-text-field
                                            class="mb-4"
                                            @blur="checkUsername"
                                            hide-details="auto"
                                            v-model.trim="form.username"
                                            :prepend-inner-icon="mdiAccount"
                                            :error-messages="errors"
                                            :success-messages="success.username"
                                            :label="getTrad('Form.username') + '*'"
                                            placeholder="johnsmith"
                                            outlined
                                            dense></v-text-field>
                                    </ValidationProvider>
                                    <ValidationProvider mode="lazy" ref="providerEmail" :name="getTrad('Form.email')" rules="required|email|uniqueEmail:@userId" v-slot="{ errors }">
                                        <v-text-field
                                            class="mb-4"
                                            v-model.trim="form.email"
                                            hide-details="auto"
                                            :prepend-inner-icon="mdiEmail"
                                            :label="getTrad('Form.email') + '*'"
                                            placeholder="johnsmith@domain.com"
                                            :error-messages="errors"
                                            :success-messages="success.email"
                                            outlined
                                            dense
                                            type="email"></v-text-field>
                                    </ValidationProvider>
                                    <v-switch hide-details="auto" v-if="isAdding" v-model="form.welcomeEmail" inset dense :label="getTrad('Form.welcomeEmail')"></v-switch>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <ValidationProvider :name="getTrad('Form.password')" :rules="passwordRequired ? 'required' : ''" v-slot="{ errors }">
                                        <v-text-field
                                            class="mb-4"
                                            :disabled="passwordDisabled"
                                            :append-icon="showPassword ? mdiEye : mdiEyeOff"
                                            :type="showPassword ? 'text' : 'password'"
                                            :label="getTrad('Form.password') + (passwordRequired ? '*' : '')"
                                            outlined
                                            :messages="!isAdding && !form.randomPassword ? getTrad('Form.updatePassword') : ''"
                                            dense
                                            hide-details="auto"
                                            :error-messages="errors"
                                            @click:append="showPassword = !showPassword"
                                            v-model="form.password"></v-text-field>
                                    </ValidationProvider>

                                    <v-switch
                                        v-model="form.changePasswordRequired"
                                        inset
                                        dense
                                        hide-details="auto"
                                        :label="getTrad('Form.requirePasswordChange')"
                                        :disabled="form.randomPassword ? true : false"></v-switch>

                                    <v-switch
                                        v-model="form.randomPassword"
                                        inset
                                        class="mb-4"
                                        dense
                                        hide-details="auto"
                                        :label="getTrad('Form.generatePassword')"
                                        @change="form.randomPassword ? (form.changePasswordRequired = true) : ''"></v-switch>

                                    <ValidationProvider :name="getTrad('Form.role')" rules="required" v-slot="{ errors }">
                                        <v-select
                                            class="mb-4"
                                            v-model="form.role"
                                            outlined
                                            dense
                                            item-value="id"
                                            item-text="name"
                                            hide-details="auto"
                                            :error-messages="errors"
                                            :items="roleOptions"
                                            :label="getTrad('Form.role') + '*'"></v-select>
                                    </ValidationProvider>

                                    <ValidationProvider :name="getTrad('Form.status')" rules="required" v-slot="{ errors }">
                                        <v-select
                                            class="mb-4"
                                            hide-details="auto"
                                            v-model="form.status"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            :items="statusOptions"
                                            :label="getTrad('Form.status') + '*'"></v-select>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <div class="d-flex justify-center mt-4">
                                <v-btn type="reset" @click="onCancel" outlined class="mr-2"> {{ getTrad('Form.cancel') }} </v-btn>
                                <v-btn color="primary" class="mr-2" type="submit"> {{ getTrad('Form.save') }} </v-btn>
                            </div>
                        </v-form>
                    </ValidationObserver>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import { avatarText } from '@/@core/utils/filter';
    import { resolveImg } from '@/utils/img';
    import { mdiClose, mdiEye, mdiEyeOff, mdiAccount, mdiEmail } from '@mdi/js';
    // import getModel from '../../models';
    import getTrad from '../../i18n/getTrad';
    import { isString, get, assign, merge } from 'lodash';
    import { addUser, updateUser } from '../../api/users';
    import { uploadFile } from '../../api/upload';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    const initialState = () => {
        return {
            isDisabled: false,
            isLoading: false,
            file: null,
            showPassword: false,
            form: {
                name: null,
                username: null,
                email: null,
                password: null,
                role: null,
                welcomeEmail: null,
                status: null,
                picture: null,
                changePasswordRequired: false,
                randomPassword: false,
            },
            success: {
                username: null,
                email: null,
            },
        };
    };

    export default {
        name: 'FormUser',
        model: {
            prop: 'isFormActive',
            event: 'update:isFormActive',
        },
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        mixins: [getTrad],
        props: {
            isFormActive: {
                type: Boolean,
                required: true,
            },
            roleOptions: {
                type: Array,
                required: true,
            },
            statusOptions: {
                type: Array,
                required: true,
            },
            action: {
                type: String,
                default: 'add',
            },
            user: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                ...initialState(),
                resolveImg,
                avatarText,
                mdiClose,
                mdiEye,
                mdiEyeOff,
                mdiAccount,
                mdiEmail,
            };
        },
        watch: {
            'form.username'() {
                this.checkUsername();
            },
            isFormActive(val) {
                if (val) {
                    if (this.isAdding) {
                        this.form = merge({}, initialState().form);
                    } else {
                        this.form = merge({}, this.user);
                        this.form.password = null;
                    }
                }
            },
        },
        computed: {
            hasPicture() {
                return isString(get(this.form.picture, 'url', null));
            },
            passwordRequired() {
                return this.isAdding && !this.form.randomPassword;
            },
            passwordDisabled() {
                return this.form.randomPassword;
            },
            isAdding() {
                return this.action === 'add';
            },
        },
        methods: {
            checkUsername() {
                this.$nextTick(() => {
                    const {
                        flags: { pending },
                    } = this.$refs.providerUsername;

                    if (pending) {
                        setTimeout(() => {
                            this.checkUsername();
                        }, 500);
                        return;
                    }
                    const {
                        flags: { valid, validated },
                    } = this.$refs.providerUsername;
                    if (valid && validated) {
                        this.success.username = this.getTrad('validations.usernameAvailable');
                    } else {
                        this.success.username = null;
                    }
                });
            },
            setUsername() {
                if (!this.form.username && this.form.name) {
                    let split = this.form.name.split(' ');
                    let firstName = split[0];
                    let lastName = split.length > 1 ? split[split.length - 1] : '';
                    this.form.username = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
                    this.$nextTick(() => {
                        this.$refs.providerUsername.validate();
                    });
                }
            },
            onCancel() {
                this.$refs.observerFormUser.reset();
                merge(this.$data, initialState());
                this.$emit('update:isFormActive', false);
            },
            async onUpload() {
                this.isLoading = true;
                await uploadFile(this.file).then(({ data }) => {
                    this.form.picture = data[0];
                });
                this.isLoading = false;
            },
            async onSubmit() {
                if (this.isAdding) {
                    await this.onAddUser();
                } else {
                    await this.onUpdateUser();
                }
            },
            async onAddUser() {
                this.isLoading = true;
                let form = assign({}, this.form);
                form.picture = get(form.picture, 'id', null);
                if (form.randomPassword) delete form.password;
                await addUser(form)
                    .then(() => {
                        this.$snackbar({
                            text: this.getTrad(`Form.${this.action}.user`),
                            color: 'success',
                            timeout: 2000,
                        });
                        this.$emit('action');
                        this.onCancel();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$snackbar({
                            text: this.$t('shared.commonError'),
                            color: 'error',
                        });
                    });
                this.isLoading = false;
            },
            async onUpdateUser() {
                this.isLoading = true;
                let form = assign({}, this.form);
                let id = form.id;
                form.picture = get(form.picture, 'id', null);
                if (form.randomPassword || !form.password) delete form.password;
                delete form.id;
                await updateUser(id, form)
                    .then(() => {
                        this.$snackbar({
                            text: this.getTrad(`Form.${this.action}.success`),
                            color: 'success',
                            timeout: 2000,
                        });
                        this.$emit('action');
                        this.onCancel();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$snackbar({
                            text: this.$t('shared.commonError'),
                            color: 'error',
                        });
                    });
                this.isLoading = false;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .v-avatar {
        position: relative;
        .file {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(0%, 0%);
        }
    }
</style>
