import _ from 'lodash';

export const resolveImg = (picture, size) => {
    let uri = picture.url;

    const uriSize = _.get(picture, `formats.sizes.${size}.url`);
    if (uriSize) uri = uriSize;
    // else console.warn(`${img.name} has no ${size} size`);

    return process.env.VUE_APP_MEDIA_URL + uri;
};
