var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.isFormActive,"max-width":"850"}},[_c('v-card',{attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.getTrad(("Form." + _vm.action + ".user"))))])]),_c('v-card-text',[_c('v-container',[_c('ValidationObserver',{ref:"observerFormUser",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-avatar',{staticClass:"v-avatar mb-4",class:_vm.hasPicture ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":_vm.hasPicture ? '' : 'primary',"tile":"","size":"120px"}},[(_vm.hasPicture)?_c('v-img',{attrs:{"src":_vm.resolveImg(_vm.form.picture, 'thumbnail')}}):_c('span',{staticClass:"font-weight-medium display-2"},[_vm._v(_vm._s(_vm.avatarText(_vm.form.name)))]),_c('v-file-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isDisabled),expression:"!isDisabled"}],staticClass:"file",attrs:{"hide-input":""},on:{"change":_vm.onUpload},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"vid":"userId"}},[_c('v-text-field',{model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})],1),_c('ValidationProvider',{attrs:{"name":_vm.getTrad('Form.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"error-messages":errors,"prepend-inner-icon":_vm.mdiAccount,"label":_vm.getTrad('Form.name') + '*',"placeholder":"John Smith","hide-details":"auto","outlined":"","dense":""},on:{"change":_vm.setUsername},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerUsername",attrs:{"mode":"lazy","name":_vm.getTrad('Form.username'),"rules":"required|uniqueUsername:@userId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"hide-details":"auto","prepend-inner-icon":_vm.mdiAccount,"error-messages":errors,"success-messages":_vm.success.username,"label":_vm.getTrad('Form.username') + '*',"placeholder":"johnsmith","outlined":"","dense":""},on:{"blur":_vm.checkUsername},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.username"}})]}}],null,true)}),_c('ValidationProvider',{ref:"providerEmail",attrs:{"mode":"lazy","name":_vm.getTrad('Form.email'),"rules":"required|email|uniqueEmail:@userId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"hide-details":"auto","prepend-inner-icon":_vm.mdiEmail,"label":_vm.getTrad('Form.email') + '*',"placeholder":"johnsmith@domain.com","error-messages":errors,"success-messages":_vm.success.email,"outlined":"","dense":"","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})]}}],null,true)}),(_vm.isAdding)?_c('v-switch',{attrs:{"hide-details":"auto","inset":"","dense":"","label":_vm.getTrad('Form.welcomeEmail')},model:{value:(_vm.form.welcomeEmail),callback:function ($$v) {_vm.$set(_vm.form, "welcomeEmail", $$v)},expression:"form.welcomeEmail"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.getTrad('Form.password'),"rules":_vm.passwordRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-4",attrs:{"disabled":_vm.passwordDisabled,"append-icon":_vm.showPassword ? _vm.mdiEye : _vm.mdiEyeOff,"type":_vm.showPassword ? 'text' : 'password',"label":_vm.getTrad('Form.password') + (_vm.passwordRequired ? '*' : ''),"outlined":"","messages":!_vm.isAdding && !_vm.form.randomPassword ? _vm.getTrad('Form.updatePassword') : '',"dense":"","hide-details":"auto","error-messages":errors},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('v-switch',{attrs:{"inset":"","dense":"","hide-details":"auto","label":_vm.getTrad('Form.requirePasswordChange'),"disabled":_vm.form.randomPassword ? true : false},model:{value:(_vm.form.changePasswordRequired),callback:function ($$v) {_vm.$set(_vm.form, "changePasswordRequired", $$v)},expression:"form.changePasswordRequired"}}),_c('v-switch',{staticClass:"mb-4",attrs:{"inset":"","dense":"","hide-details":"auto","label":_vm.getTrad('Form.generatePassword')},on:{"change":function($event){_vm.form.randomPassword ? (_vm.form.changePasswordRequired = true) : ''}},model:{value:(_vm.form.randomPassword),callback:function ($$v) {_vm.$set(_vm.form, "randomPassword", $$v)},expression:"form.randomPassword"}}),_c('ValidationProvider',{attrs:{"name":_vm.getTrad('Form.role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-4",attrs:{"outlined":"","dense":"","item-value":"id","item-text":"name","hide-details":"auto","error-messages":errors,"items":_vm.roleOptions,"label":_vm.getTrad('Form.role') + '*'},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.getTrad('Form.status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-4",attrs:{"hide-details":"auto","error-messages":errors,"outlined":"","dense":"","items":_vm.statusOptions,"label":_vm.getTrad('Form.status') + '*'},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center mt-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"type":"reset","outlined":""},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.getTrad('Form.cancel'))+" ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.getTrad('Form.save'))+" ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }